/* eslint-disable perfectionist/sort-imports */
import "@solvari/common-fe/polyfills";
import "@/platform/utils/sentry";
import "@/vue/utils/solvariConfig";

import { initArgus } from "@solvari/common-fe/argus";
import { initAbTesting } from "@solvari/common-fe/ab-testing";
import { initHeyflowForm } from "@/shared/components/heyflow-form/heyflowForm";

import "@/shared/components/trustpilot-rating/trustpilotRating.css";
import "@/platform/layouts/default/default.css";
import "@/platform/components/header/header";
import "@/platform/components/footer/footer";

import "@/platform/components/vueIslands";
/* eslint-enable perfectionist/sort-imports */

void initArgus();
void initAbTesting();
void initHeyflowForm();
